import * as React from "react"
import '../../gatsby-browser.js';
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { motion } from "framer-motion"
import axios from "axios";
import {url} from "../url/url";
// import ReactHtmlParser from "react-html-parser";
import Load from '../static/img/load/load.gif'
import {Link} from "gatsby";
import MetaTags from "../component/MetaTags.jsx";


class BlogDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            data:[],
            loading: true,
        }

    }

    componentDidMount() {

        let main = this.props.location.state.id;

        if( typeof window !== 'undefined' ) {
            axios({
                method: 'GET',
                url: url + '/blogs/' + main + '?_locale=' + localStorage.getItem('lan'),
            })
                .then((result) => {
                    // console.log(result.data)
                    this.setState({
                        data: result.data,
                        loading: false
                    })
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    })
                })
        } else if( typeof window !== 'undefined' ) {
            axios({
                method: 'GET',
                url: url + '/blogs/' + main + '?_locale=' + "en",
            })
                .then((result) => {
                    // console.log(result.data)
                    this.setState({
                        data: result.data,
                        loading: false
                    })
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    })
                })
        }

    }

    render(){
        const pageVariants = {
            initial: {
                opacity: 0,
            },
            in: {
                opacity: 1,
            },
            out: {
                opacity: 0,
            },
        }

        const { data, loading } = this.state;

        // console.log(data)

        return(
            <>

                <Navbar />

                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                >
                    <section id="blogs_detail" className="blogs_detail">
                        <div align="center">
                            <h5 className="koderbox_title"> KoderBox </h5>
                            <h1 className="whatwedo">
                                {data.title}
                            </h1>
                        </div>
                    </section>
                    <br /><br /><br />
                    <div className="container">
                        <div className="row">

                            {loading ? (
                                <>
                                    <div align="center" className="mt-5 mb-5">
                                        <img src={Load} />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div align={"center"}>
                                        {data.second == undefined ? (
                                            <></>
                                        ) : (
                                            <>
                                                <img src={url+data.second.url} className={"header_image"} />
                                            </>
                                        )}


                                    </div>
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm-8 blog-content" align="left">
                                        <br/><br/>
                                        {/* {ReactHtmlParser(data.content)} */}
                                        {data.content}
                                    </div>
                                    <div className="col-sm-2"></div>
                                </>
                            )}

                        </div>
                    </div>
                    <br />
                    <Footer value={data} />
                </motion.div>
                <MetaTags title={"Blogs | Koderbox"} />
            </>
        )
    }
}

export default BlogDetail